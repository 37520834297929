<template>
  <div class="d-flex ms-auto" v-if="actions.length > 0">
    <v-menu transition="slide-y-transition" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            btn-default
            bg-gradient-default
            py-2
            px-6
            me-2
          "
          color="transparent"
          small
          dark
        >
          Actions
          <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
        </v-btn>
      </template>

      <v-list class="py-0">
        <v-list-item
          v-for="(action, i) in actions"
          :key="i"
          class="list-item-hover-active py-2"
          @click="onClick(action)"
        >
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
            >
              {{
                action
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    actions: { type: Array, required: true },
  },
  methods: {
    onClick(item) {
      this.$emit("onClick", item);
    },
  },
};
</script>
