<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-form ref="frm">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-header-padding">
            <div class="d-flex align-center justify-space-between">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  IRMC Details
                </h5>
              </div>
              <v-spacer></v-spacer>
              <action-button
                :actions="currentPage.actions_allowed"
                @onClick="allowedAction"
              />
              <v-btn
                @click="back"
                elevation="0"
                :ripple="false"
                dark
                height="43"
                color="blue"
                class="text-capitalize btn-ls py-3 px-6 mx-2 blue"
                >Back</v-btn
              >
            </div>
          </div>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <v-container class="px-0">
                <v-row>
                  <v-col
                    ><h1 class="font-weight-bold text-h5 text-typo mb-0">PO</h1>
                    {{ po_number }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >Ref</label
                    >
                    <!-- :disabled="!currentPage.permissions.includes('EDIT')" -->
                    <v-text-field
                      v-model="ref"
                      placeholder="Enter Ref"
                      :rules="[(v) => !!v || 'Ref is required']"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      ><span class="red--text"><strong>* </strong></span
                      >Date</label
                    >

                    <v-menu
                      ref="mnu_date"
                      v-model="mnu_date"
                      :close-on-content-click="false"
                      :return-value.sync="mnu_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="incoming_date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-5
                          "
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          placeholder="Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="incoming_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="mnu_date = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.mnu_date.save(incoming_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >Name Of Supplier</label
                    >
                    <!-- :disabled="!currentPage.permissions.includes('EDIT')" -->
                    <v-text-field
                      v-model="supplier_name"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Enter Name Of Supplier"
                      :rules="[(v) => !!v || 'Name Of Supplier is required']"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >No: Of Bags</label
                    >
                    <!--  :disabled="!currentPage.permissions.includes('EDIT')" -->
                    <v-text-field
                      placeholder="Enter No: Of bags"
                      :rules="[(v) => !!v || 'No: Of bags is required']"
                      v-model.number="bags"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >Bill No</label
                    >

                    <!-- :disabled="!currentPage.permissions.includes('EDIT')" -->
                    <v-text-field
                      v-model="bill_no"
                      placeholder="Enter Bill No"
                      :rules="[(v) => !!v || 'Bill No is required']"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      persistent-hint
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >Vehicle No:</label
                    >
                    <!--  :disabled="!currentPage.permissions.includes('EDIT')" -->
                    <v-text-field
                      hide-details="auto"
                      v-model="vehicle_no"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Enter Vehicle No;"
                      :rules="[(v) => !!v || 'Vehicle No: is required']"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row> </v-row>

                <v-simple-table>
                  <template v-slot:default>
                    <tr v-for="(item, i) in questions" :key="item.id">
                      <td>{{ item.question }}</td>
                      <td>
                        <v-checkbox
                          v-model="ans[i]"
                          label="Yes"
                          color="green"
                          value="true"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td>
                        <v-checkbox
                          v-model="ans[i]"
                          label="No"
                          color="red"
                          value="false"
                          hide-details
                        ></v-checkbox>
                      </td>
                    </tr>
                  </template>
                </v-simple-table>
                <v-row>
                  <v-col cols="12" offset-md="8" md="4">
                    <!-- <v-btn
                      @click="cancel"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        text-capitalize
                        btn-ls btn-secondary
                        bg-light
                        py-3
                        px-6
                        ml-2
                      "
                      >Cancel</v-btn
                    > -->
                    <!-- v-show="currentPage.permissions.includes('EDIT')" -->
                    <v-btn
                      @click="save"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        ml-2
                      "
                      >Update</v-btn
                    >
                    <!-- v-show="currentPage.permissions.includes('DELETE')" -->
                    <v-btn
                      @click="deleteItem"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        ml-2
                      "
                      color="red"
                      >DELETE</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import ActionButton from "../../../../components/ActionButton.vue";
import api from "../api";
import formatDate from "../../../../global-data/dateFormatter";
// import { mapGetters } from "vuex";
export default {
  components: { ActionButton },
  data() {
    return {
      id: this.$route.params.id,
      currentPage: this.$route.query.currentPage,
      ref: "",
      supplier_name: "",
      po_number: "",
      bill_no: "",
      vehicle_no: "",
      dispacthMaster: "",
      editedId: -1,
      bags: 0,
      overlay: false,
      boolTrue: true,
      ans: [],
      all_questions: [],
      questions: [],

      incoming_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      mnu_date: false,

      frm_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_frm_date: false,

      to_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_to_date: false,
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.all_questions = await api.getQuestion();
      let [result] = await api.getById(this.id);
      this.bill_no = result.bill_no;
      this.incoming_date = formatDate.formatDate(result.incoming_date);
      this.supplier_name = result.name_of_supplier;
      this.bags = result.number_of_bags;
      this.po_number = result.po_number.map((el) => el.po_number).join(" , ");
      console.log("this.po_number", this.po_number);

      this.dispacthMaster = result.dispatch_master_id;
      this.ref = result.ref;
      this.vehicle_no = result.vehicle_no;
      this.questions = this.all_questions;
      this.questions.forEach((el) => {
        let i = result.irmc_child.findIndex((q) => q.irmcQuestionId == el.id);

        if (i > -1) {
          this.ans[i] = result.irmc_child[i].qc_status + "";
        }
      });
    },

    validate() {
      return this.$refs.frm.validate();
    },
    async deleteItem() {
      this.overlay = true;
      try {
        api.deteleMaster(this.id);

        this.showSuccessAlert("Data Deleted");
        setTimeout(() => {
          this.$router.push({ name: "IRMC" });
        }, 2000);
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async save() {
      if (this.validate()) {
        let array = [];
        if (this.ans.flat().length == this.questions.length) {
          this.overlay = true;
          this.questions.forEach((el, i) => {
            let status = false;
            if (this.ans[i] == "false") {
              status = false;
            } else {
              status = true;
            }
            let d = {
              question: el,
              qc_status: status,
            };
            array.push(d);
          });
          let req = {
            ref: this.ref,
            incoming_date: new Date(this.incoming_date),
            name_of_supplier: this.supplier_name,
            dispacthMaster: this.dispacthMaster,
            bill_no: this.bill_no,
            vehicle_no: this.vehicle_no,
            number_of_bags: this.bags,
            irmc_child: array,
          };
          console.log(req);
          try {
            await api.saveData(req, this.id);
            this.overlay = false;
            this.showSuccessAlert("Data Saved");

            setTimeout(() => {
              this.$router.push({ name: "IRMC" });
            }, 2000);
          } catch (error) {
            this.showErrorAlert(error);
          }
        } else {
          this.showWarningAlert("Please Answer All Question");
        }
      }
    },
    back() {
      this.$router.push({ name: "IRMC" });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: true,
        type: "error",

        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    cancel() {
      this.editedId = -1;
      this.ref = "";
      this.name_of_supplier = "";
      this.bill_no = "";
      this.vehicle_no = "";
      this.number_of_bags = 0;
      this.ans = [];
      this.dialog = false;
      this.$refs.frm.resetValidation();

      // this.initialize();
    },
  },
};
</script>

<style></style>
