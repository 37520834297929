import axios from "axios";

export default {
  async getData() {
    return await axios
      .get("irmc")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async search(fromDate, toDate) {
    return await axios
      .get(`irmc?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getById(id) {
    return await axios
      .get("irmc/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getQuestion() {
    return await axios
      .get("irmc/getQuestions")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async saveData(req, id) {
    axios
      .patch("irmc/" + id, req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async allowedAction(req) {
    axios
      .post("irmc/action/", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async deteleMaster(id) {
    axios
      .delete("irmc/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
